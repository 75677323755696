import React, { Component } from "react";
import { connect } from "react-redux";
import { Intent } from "@blueprintjs/core";
import ModalHeader from "../../components/modals/ModalHeader";
import InputField from "../../components/fields/InputField";
import { ttfResetPassword } from "../../services/http_services/to_ttf_requests";
import { showToast } from "../../actions/ui";
import LoginModal from "../../containers/auth/login_modal";
import { createModal, removeModal } from "../../actions/ui";
import { navigate } from "gatsby";

class ResetPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: false,
      resetCompleted: false,
    };
  }

  componentDidMount() {
    const token = window.location.search.split("=")[1];
    if (!token) {
      navigate("/");
    }
  }

  onInputChange = (fieldKey, value) => {
    this.setState({
      [fieldKey]: value,
    });
  };

  handleLoginSuccess = () => {
    navigate("/");
  };

  openLoginModal = () => {
    const { createModal, removeModal } = this.props;

    const modal = {
      id: "login_modal",
      Component: () => (
        <div
          style={{
            maxWidth: this.isMobileView ? "100%" : "480px",
            height: "inherit",
            display: "inline-flex",
            maxHeight: 550,
          }}
        >
          <LoginModal
            removeModal={removeModal}
            onLoginSuccess={this.handleLoginSuccess}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: this.isMobileView,
    };
    this.setState({ expanded: false }, () => {
      createModal(modal, true);
    });
  };

  handleResetPassword = async (e) => {
    e.preventDefault();
    const { password, password_confirmation, loading } = this.state;

    if (loading) {
      return;
    }

    const token = window.location.search.split("=")[1];

    try {
      const dataObj = {
        password,
        password_confirmation,
        token,
      };

      try {
        this.setState({ loading: true });
        const data = await ttfResetPassword(dataObj);

        if (data.success) {
          this.setState({
            loading: false,
            resetCompleted: true,
          });
          this.openLoginModal();
        }
      } catch (e) {
        const { data } = e.response;

        this.setState({
          error: data.message,
          loading: false,
        });
      }
    } catch (error) {
      showToast("Something Wrong", Intent.DANGER);
    }
  };

  render() {
    const { password, password_confirmation, error, loading, resetCompleted } =
      this.state;

    return (
      <div className="reset_password_main_container">
        {!resetCompleted && (
          <div className="rn_window forgot_password_container">
            <ModalHeader title="Reset Your Password" />
            <form
              className="reset_block_form"
              onSubmit={this.handleResetPassword}
            >
              {error && (
                <p className="reset_password_server_error_block">{error}</p>
              )}
              <div className="reset_window_content">
                <div className="reset_password_block">
                  <InputField
                    minLength={6}
                    maxLength={32}
                    editable={Boolean(true)}
                    required={Boolean(true)}
                    label="Password"
                    name="password"
                    type="password"
                    initialValue=""
                    value={password}
                    handleChange={this.onInputChange}
                  />
                </div>
                <div className="reset_password_block">
                  <InputField
                    editable={Boolean(true)}
                    required={Boolean(true)}
                    label="Confirm Password"
                    name="password_confirmation"
                    type="password"
                    initialValue=""
                    value={password_confirmation}
                    handleChange={this.onInputChange}
                  />
                </div>
                <div
                  className={`forgot_password_submit ${loading ? "disable_button" : ""
                    }`}
                >
                  <button disabled={loading} type="submit">
                    RESET PASSWORD
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
  };
};

export default connect(null, mapDispatchToProps)(ResetPasswordModal);
