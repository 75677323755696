import React, { Component, Fragment } from "react";
import Footer from "@components/global/footer/footer";
import { setTfCheckoutReactConfigs } from "../utils/utils";
import "../styles/main.scss"
import ResetPasswordModal from "../containers/auth/reset_password_modal";

setTfCheckoutReactConfigs();

class ResetPassword extends Component {
  render() {
    return (
      <Fragment>
        <ResetPasswordModal />
        <Footer />
      </Fragment>
    );
  }
}

export default ResetPassword;

export const Head = () => <title>Restless Nites</title>;
